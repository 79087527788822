<template>
    <div>
        <br>
        <br>
        <div class="col d-sm-flex justify-content-center">
            <div class="card">
                <div v-if="confirmed" class="text-center">
                    <h2>La procedura di registrazione è andata a buon fine.</h2>
                    <p>Vai alla pagina di login per entrare con le tue credenziali. Ricordati di cambiare subito la tua password.</p>
                    <router-link class="btn btn-primary" to="/login">Vai al login</router-link>
                </div>
                <div v-else>
                    <p>È stata richiesta la tua iscrizione al nostro portale, prima di continuare devi accettare le condizioni sulla privacy.</p>
                    <b-form-checkbox
                        id="privacy"
                        v-model="privacy"
                        name="privacy">
                        Dichiaro di aver letto e accettato l'<a :href="privacyUrl" target="_blank">informativa sul trattamento dei dati</a>.
                    </b-form-checkbox>
                    <p><span v-if="errorMsg" class="text-danger">{{ errorMsg }}</span>&nbsp;</p>
                    <b-button @click="confirmProfile" variant="success">Accetta e continua</b-button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import ProfileDataService from "@/components/profile/ProfileDataService";
import {HomepageByRole} from "@/helpers/HomepageByRole";

export default {
    name: "confirm-profile",
    data() {
        return {
            confirmed: false,
            privacy: null,
            errorMsg: null,
            privacyUrl: 'https://www.toccafondimultimedia.com/privacy/',
            tmpData: {
                state: {},
            }
        };
    },
    props: ["token","keepSession"],
    methods: {
        confirmProfile() {
            if (!this.privacy) {
                this.errorMsg = 'È necessario accettare le condizioni della privacy per poter procedere.';
            } else ProfileDataService.confirmProfile(this.token)
                .then(response => {
                    this.errorMsg = null;
                    this.confirmed = true
                })
                .catch(err => {
                    console.log(err)
                })
        },
        resetDataStore() {
            localStorage.removeItem('authUser');
            this.$store.state.isLoggedIn = false;
            this.$store.state.user = null;
            this.$store.state.token = null;
        }
    },
    mounted() {
        if (this.$store.state.isLoggedIn) {
            // reset data storage
            this.resetDataStore();
        }
    }
};
</script>

<style scoped>

</style>
